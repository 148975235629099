<template>
	<b-modal return-focus=".view-title" size="lg" title="학교 변경" id="school-change-modal" hide-footer @hide="initializeModal">
		<b-row>
			<b-col cols="9">
				<b-form-input class="input" v-model="keyword" :state="keyword_validation" placeholder="학교 이름으로 검색해주세요." v-on:keyup.enter="makeSearch" />
			</b-col>
			<b-col cols="3">
				<b-button class="orange" type="submit" v-on:click="makeSearch"> 검색 </b-button>
			</b-col>
		</b-row>

		<b-container v-if="search_conducted && !search_validation">
			<b-row align-h="center" v-if="!makeSchool">
				<b-col style="text-align:center;">{{ search_feedback }}</b-col>
			</b-row>
			<b-row align-h="center" v-if="!makeSchool">
				<b-col style="text-align:center;">주소를 입력하여 직접 등록하실 수 있습니다.</b-col>
			</b-row>
			<b-row align-h="center" v-if="!makeSchool">
				<b-button class="orange" v-on:click="makeSchool = true">직접 입력하기</b-button>
			</b-row>
			<b-form-input v-if="makeSchool" class="input" v-model="school_name" placeholder="학교명" />
			<b-form-input v-if="makeSchool" class="input" v-model="school_address" placeholder="학교주소" />
			<b-form-input v-if="makeSchool" class="input" v-model="zipcode" placeholder="우편번호" />
			<b-row v-if="makeSchool && !post_school_validation" align-h="center">
				<b-col style="text-align:center;"> 모든 항목을 입력해주셔야 합니다. </b-col>
			</b-row>
			<b-button v-if="makeSchool && !post_school_validation" disabled>입력 완료</b-button>
			<b-button v-if="makeSchool && post_school_validation" v-on:click="postSchool">입력 완료</b-button>
		</b-container>

		<b-table v-if="search_validation" small hover :fields="fields" :items="school_list" primary-key="id" @row-clicked="rowSelect" />
	</b-modal>
</template>

<script>
export default {
	name: 'ProfileSchoolChangeModal',
	data() {
		return {
			keyword: '', // 팝업창 검색어
			school_list: [], // 팝업창 검색 결과용
			search_feedback: '', // 검색 결과 없을 시 피드백
			search_conducted: false,

			school_name: '', // 클라이언트 조회용
			school_id: -1, // 서버 전송용

			makeSchool: false, // 학교 직접 입력 여부
			school_address: '', // 학교 직접 입력 시 주소
			zipcode: '',

			fields: [
				{ key: 'name', label: '학교명' },
				{ key: 'address', label: '주소' },
				{ key: 'zipcode', label: '우편번호' },
			],
		};
	},
	computed: {
		// 필수 입력 처리
		school_validation() {
			return this.school_name.length > 0;
		},

		// 두 글자 이상 검색
		keyword_validation() {
			return this.keyword.length > 1;
		},

		// 검색 결과 있는지 없는지 확인
		search_validation() {
			return this.school_list.length > 0;
		},

		post_school_validation() {
			return this.school_name.length > 0 && this.school_address.length > 0 && this.zipcode.length > 0;
		},
	},
	methods: {
		// 학교 검색 요청
		makeSearch() {
			this.$store
				.dispatch('SEARCH_SCHOOL', {
					keyword: this.keyword,
				})
				.then((res) => {
					// 검색 결과 정상일 경우
					if (res.status === 200) {
						this.search_conducted = true;
						this.school_list = res.data.results;
						this.makeSchool = false;
						this.search_feedback = '';
						// 검색 결과가 없는 경우 (에러 아님)
						if (this.school_list.length === 0) {
							this.search_feedback = '찾으시는 학교가 없습니다.';
						}
					}

					// 토큰 만료로 인한 요청 실패
					if (this.$store.state.serverStatus === 401) {
						this.$router.push('/');
						window.location.reload();
						alert('다시 회원가입 하십시오.');
					}
				});
		},

		// 학교 직접 입력
		postSchool() {
			if (confirm('입력한 정보가 맞습니까?')) {
				this.$store
					.dispatch('POST_SCHOOL', {
						school_name: this.school_name,
						school_address: this.school_address,
						zipcode: this.zipcode,
					})
					.then((res) => {
						if (res.status === 201) {
							this.school_id = res.data.id;
							this.patchProfile(this.school_id);
						}
					});
			}
		},

		// 팝업창 검색 결과에서 학교 선택
		rowSelect(item) {
			this.school_id = item.id; // 서버 전송용
			this.school_name = item.name; // 클라이언트용
			if (confirm(this.school_name + '가 선생님 소속 학교가 맞습니까?')) {
				this.patchProfile(this.school_id);
			}
		},

		// 학교 검색 input 필드 focus되면 모달 띄우기 (focus & click 둘 다 됨)
		focusSearch(modal) {
			if (this.school_name === '') {
				this.$bvModal.show(modal);
			}
		},

		// 회원가입 2단계 요청
		patchProfile(schoolId) {
			// actions 메소드 호출
			this.$store
				.dispatch('PATCH_PROFILE', {
					school: schoolId,
				})
				.then((res) => {
					// 서버 응답이 정상일 경우
					if (this.$store.state.serverStatus === 200) {
						// 로그인 페이지로 돌아감
						// 중복 이동을 막기 위한 에러 처리
						this.$bvModal.hide('school-change-modal');
						this.$emit('schoolChanged');
					}
					return res;
				});
		},

		initializeModal() {
			this.search_conducted = this.makeSchool = false;
			this.keyword = '';
			this.school_list = [];
			this.search_feedback = '';
			this.school_name = '';
			this.school_id = -1;
			this.school_address = '';
			this.zipcode = '';
		},
	},
};
</script>

<style scoped>
.input {
	padding: 0.5em;
	margin: auto;
	margin-top: 1em;
	width: 90%;
	color: #000000;
	font-size: 0.75em;
	border-color: #000000;
	border-width: 1px;
	border-radius: 2mm;
	background-color: #ffffff;
}
.btn {
	margin-top: 1em;
	width: 90%;
}
.modal-body .input,
.modal-body .btn {
	font-size: 1em;
	width: 100%;
}

.modal-body .container {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.modal-body .table {
	margin-top: 1rem;
}

.table > * {
	border-top: none;
}

@media (min-width: 992px) {
	.form-wrap {
		width: 592px;
	}
	.logo {
		display: flex;
	}
}
</style>
