<template>
	<!--   <b-modal title="비밀번호 변경" id="password-change-modal" ok-only ok-title="취소" ok-variant="secondary"> -->
	<b-modal return-focus=".view-title" title="비밀번호 변경" id="password-change-modal" hide-footer @hide="initializeData">
		<b-form-group id="current-password" invalid-feedback="현재 비밀번호를 다시 확인해주세요." :state="current_password_validation">
			<b-form-input class="input" type="password" placeholder="현재 비밀번호" v-model="current_password" @input="validateCurrentPassword" />
		</b-form-group>

		<b-form-group id="password1" :state="password1_empty || password1_validation" invalid-feedback="비밀번호는 영문과 숫자를 포함하여 10자 이상으로 입력해주세요.">
			<b-form-input class="input" type="password" placeholder="신규 비밀번호" v-model="password1" debounce="300" />
		</b-form-group>

		<b-form-group id="password2" :state="password2_empty || password2_validation" invalid-feedback="비밀번호가 일치하지 않습니다.">
			<b-form-input class="input" type="password" placeholder="신규 비밀번호 재입력" v-model="password2" debounce="300" />
		</b-form-group>

		<b-row align-h="center">
			<b-col class="text-center">
				<b-button class="orange" v-if="!passwordCheck || button_pressed" disabled> <b-spinner v-if="button_pressed" small></b-spinner> 비밀번호 변경 </b-button>
				<b-button class="orange" v-if="passwordCheck && !button_pressed" type="submit" variant="primary" v-on:click="makeChangePassword"> 비밀번호 변경 </b-button>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
export default {
	name: 'PasswordChangeModal',
	data() {
		return {
			// 유저 기본 데이터
			current_password: '',
			password1: '',
			password2: '',
			current_password_validation: true,
			button_pressed: false,
		};
	},
	computed: {
		// 비밀번호 영문, 숫자, 10글자 이상 검사
		password1_validation() {
			const reg = /^.*(?=.{10,64})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
			return reg.test(this.password1);
		},
		// 비밀번호 일치하는지 검사
		password2_validation() {
			if (this.password2.length > 0 && this.password1 === this.password2) {
				return true;
			}
			return false;
		},
		password1_empty() {
			return this.password1.length === 0;
		},
		password2_empty() {
			return this.password2.length === 0;
		},
		passwordCheck() {
			if (this.password1_validation && this.password2_validation) {
				return true;
			}
			return false;
		},
	},
	methods: {
		// 유저 정보 수정 요청
		makeChangePassword() {
			// 연속으로 안 눌리게 쿨타임 주기
			this.button_pressed = true;
			setTimeout(() => {
				this.button_pressed = false;
			}, 250);

			this.$store
				.dispatch('CHANGE_PASSWORD', {
					current_password: this.current_password,
					new_password: this.password1,
					re_new_password: this.password2,
				})
				.then((res) => {
					// console.log(this.$store.state.serverStatus)
					// 요청 정상 처리
					if (this.$store.state.serverStatus === 204) {
						alert('비밀번호 변경 완료');
						this.$bvModal.hide('password-change-modal');
					}
					// 토큰 기간 만료로 요청 실패
					if (this.$store.state.serverStatus === 401) {
						this.$router.push('/');
						window.location.reload();
					}
					if (this.$store.state.serverStatus === 400) {
						// if (res.data.hasOwnProperty('current_password')) {
						if (Object.prototype.hasOwnProperty.call(res.data, 'current_password')) {
							this.current_password_validation = false;
						}
						// console.log('error changing password')
					}
				});
		},
		validateCurrentPassword() {
			this.current_password_validation = true;
		},
		initializeData() {
			this.current_password = this.password1 = this.password2 = '';
		},
	},
};
</script>

<style scoped></style>
