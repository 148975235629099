<template>
  <b-modal return-focus=".view-title" title="회원 탈퇴" id="delete-user-modal" hide-footer @hide="initializeData">
    <b-form-group id="current-password"
    invalid-feedback="비밀번호를 확인해주세요." :state="current_password_validation">
      <b-form-input class="input" type="password" placeholder="회원 탈퇴를 위해서는 비밀번호를 입력해주셔야 합니다."
      v-model="current_password" @input="validateCurrentPassword" />
    </b-form-group>

    <b-form-group id="current_email" :state="email_input_empty||email_input_validation" invalid-feedback="이메일을 정확히 입력해주세요.">
      <b-form-input class="input" type="email" placeholder="확인을 위해 가입할 때 등록한 이메일을 입력해주세요." v-model="email_input" debounce="300" />
    </b-form-group>

      <b-row>
        <b-col class="text-center">
          <b-button v-if="!inputCheck||button_pressed" disabled>
            <b-spinner v-if="button_pressed" small></b-spinner> 회원 탈퇴 </b-button>
          <b-button v-if="inputCheck&&!button_pressed" type="submit" variant="danger" v-on:click="makeDeleteProfile"> 회원 탈퇴 </b-button>
        </b-col>
      </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteUserModal',
  data () {
    return {
      // 유저 기본 데이터
      current_password: '',
      email_input: '',
      button_pressed: false,
      current_password_validation: true
    }
  },
  computed: {
    // current_password_validation () {
    //   return this.current_password.length > 0
    // },
    email_input_validation () {
      return this.email_input === this.$store.state.email
    },
    email_input_empty () {
      return this.email_input.length === 0
    },
    inputCheck () {
      if (this.current_password_validation && this.email_input_validation) {
        return true
      }
      return false
    }
  },
  methods: {
    makeDeleteProfile () {
      this.button_pressed = true
      setTimeout(() => { this.button_pressed = false }, 250)

      // actions 메소드 호출
      this.$store.dispatch('DELETE_PROFILE')
        .then(res => {
          if (res.status === 204) {
            // 주의: axios delete는 payload 구조가 다름.
            // 참고: https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers
            this.$store.dispatch('DELETE_USER', {'data': {
              current_password: this.current_password}
            })
              .then(res => {
                if (res.status === 204) {
                  alert('계정이 정상적으로 삭제되었습니다.')
                  this.$store.dispatch('SIGNOUT')
                  this.$bvModal.hide('delete-user-modal')
                  this.$router.push('/')
                }
              })
          }
        })
    },
    validateCurrentPassword () {
      this.current_password_validation = true
    },
    initializeData () {
      this.current_password = this.email_input = ''
    }
  }
}
</script>

<style scoped>
</style>
