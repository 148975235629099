<template>
  <b-row align-h="end" class="feedback">
    <b-col class="text-right">
      <b-link href="https://docs.google.com/forms/d/e/1FAIpQLSdhYRq44mvfYfjwAYWeGogU9is54wtgt0puCIqWQ6CIpFZMsg/viewform" target="_blank"><img src="@/assets/teacher_comment.svg" alt="의견 제안하러 가기" /></b-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FeedbackIcon',
  data () {
    return {
      show: false
    }
  },
  methods: {
    showFeedbackIcon() {
      const feedback = document.querySelector('.feedback')
      feedback.classList.add('show')
    }
  },
  mounted () {
    setTimeout(this.showFeedbackIcon, 10000)
  }
}
</script>

<style scoped>
.feedback {
  position: fixed;
  bottom: 60px;
  right: 0;
  margin-right: -200px;
  margin-top: 10px;
  z-index: 1;
  opacity: 0;
  transition: 1s;
}
.feedback img {
  width: 128px;
  border-radius: 32px;
}
.feedback.show {
  margin-right: 0px;
  opacity: 1;
}

@media (min-width: 992px) {
  .feedback {
    /*position: fixed;*/
    bottom: 30px;
    right: 20px;
  }
  .feedback img {
    width: 144px;
  }
}
@media (max-height: 667px) {
  .feedback {
    /*position: ab;*/
  }
}
</style>
