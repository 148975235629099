<template>
  <b-container class="profile-school">
    <p class="header">
      학교 정보
    </p>
    <b-row>
      <b-col class="col-4">
        <p class="cell">학교명</p>
      </b-col>
      <b-col>
        <p class="cell">{{ school.name }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-4">
        <p class="cell">교육지원청</p>
      </b-col>
      <b-col>
        <p class="cell">{{ school.district }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-4">
        <p class="cell">대표선생님</p>
      </b-col>
      <b-col>
        <p class="cell">{{ school.teacher_contactpoint.full_name }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-4">
        <p class="cell">학교 주소</p>
      </b-col>
      <b-col>
        <p class="cell">{{ school.address }}</p>
      </b-col>
    </b-row>
<!--     <b-row>
      <b-col class="col-4">
        <p class="cell">신청 과목</p>
      </b-col>
      <b-col>
        <p class="cell">{{ school.game_kits.join(', ') }}</p>
      </b-col>
    </b-row> -->
    <b-row align-h="center" class="button-row">
      <b-button :pressed="false" class="orange bold" v-b-modal.school-change-modal v-b-tooltip.hover title="소속 학교가 잘못 표시되거나 학교를 옮기셨을 경우 현재 소속 학교로 변경해주세요">학교 변경</b-button>
    </b-row>
  </b-container>
</template>

<script>

// 유저 정보 관련 마이페이지 + 로그아웃 기능
export default {
  name: 'ProfileSchool',
  props: {'school': Object}
}
</script>

<style scoped>
.btn {
  margin-top: 1em;
  width: 100%;
}
@media (min-width: 992px) {
  .profile-school {
    position: relative;
    min-height: 20em;
  }
  .button-row {
    width: 87%;
    /*margin: 0 auto;*/
    /*padding: 0;*/
    position: absolute;
    bottom: 1em;
  }
}
@media (max-width: 576px) {
  .btn {
    margin-top: 1em;
    width: 80%;
  }
  .row {
    margin: 0;
  }
  .col {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
