<template>
	<b-container class="profile-user">
		<p class="header">
			교사 정보
		</p>
		<b-row class="desktop">
			<b-col class="col-4">
				<p class="cell">&nbsp;</p>
			</b-col>
			<b-col>
				<p class="cell">&nbsp;</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="col-4">
				<p class="cell">이름</p>
			</b-col>
			<b-col>
				<p class="cell">{{ user.full_name }}</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="col-4">
				<p class="cell">이메일</p>
			</b-col>
			<b-col>
				<p class="cell">{{ user.email }}</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="col-4">
				<p class="cell">연락처</p>
			</b-col>
			<b-col>
				<p class="cell">{{ user.phone }}</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="col-4">
				<p class="cell">신청 프로그램</p>
			</b-col>
			<b-col>
				<p class="cell">{{ user.kits.join(', ') }}</p>
			</b-col>
		</b-row>
		<b-row align-h="center" class="button-row">
			<b-button :pressed="false" class="orange bold" v-b-modal.profile-edit-modal>교사 정보 수정</b-button>
		</b-row>
	</b-container>
</template>

<script>
export default {
	name: 'ProfileUser',
	props: { user: Object },
};
</script>

<style scoped>
.btn {
	margin-top: 0;
	width: 100%;
}
.desktop {
	display: none;
}
@media (min-width: 992px) {
	.profile-user {
		position: relative;
		min-height: 20em;
	}
	.button-row {
		width: 87%;
		/*margin: 0 auto;*/
		/*padding: 0;*/
		position: absolute;
		bottom: 1em;
	}
	.desktop {
		display: flex;
	}
}
@media (max-width: 576px) {
	.btn {
		margin-top: 1em;
		width: 80%;
	}
	.row {
		margin: 0;
	}
	.col {
		padding-left: 10px;
		padding-right: 10px;
	}
}
</style>
