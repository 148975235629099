<template>
	<div class="view">
		<div class="main">
			<b-row>
				<b-col class="view-title">
					<p>내 정보</p>
				</b-col>
			</b-row>

			<b-row class="default-padding" cols="1" cols-lg="2">
				<ProfileUser v-bind:user="user"></ProfileUser>

				<ProfileSchool v-bind:school="school"></ProfileSchool>
			</b-row>

			<b-row style="padding-top:3em;">
				<b-col class="text-center">
					<span class="small" style="color:#CC5050;" v-b-modal.password-change-modal>비밀번호 변경</span>
				</b-col>
			</b-row>
			<b-row style="padding-top:1em;">
				<b-col class="text-center">
					<span class="small" style="color:#CC5050;" v-b-modal.delete-user-modal>회원 탈퇴</span>
				</b-col>
			</b-row>

			<b-modal return-focus=".view-title" title="프로필 정보 수정" id="profile-edit-modal" hide-footer @hide="initializeData">
				<b-form-group id="full_name" label="이름" label-for="input_full_name" :state="full_name_validation" invalid-feedback="이름을 확인해주세요.">
					<b-form-input id="input_full_name" class="input" v-model="new_full_name" debounce="300" />
				</b-form-group>
				<b-form-group id="phone" label="전화번호" label-for="input_phone" :state="phone_validation" invalid-feedback="휴대전화 번호를 확인해주세요.">
					<b-form-input id="phone" class="input" v-model="new_phone" debounce="300" />
				</b-form-group>
				<b-row>
					<b-col class="text-center">
						<b-button class="orange" :pressed="false" v-if="!full_name_validation || !phone_validation || button_pressed" disabled>
							<b-spinner v-if="button_pressed" small></b-spinner>
							프로필 정보 변경
						</b-button>
						<b-button class="orange" :pressed="false" v-if="full_name_validation && phone_validation && !button_pressed" type="submit" variant="primary" v-on:click="putProfile">
							프로필 정보 변경
						</b-button>
					</b-col>
				</b-row>
			</b-modal>

			<PasswordChangeModal></PasswordChangeModal>
			<DeleteUserModal></DeleteUserModal>
			<ProfileSchoolChangeModal v-on:schoolChanged="reloadWindow"></ProfileSchoolChangeModal>
			<FeedbackIcon />
		</div>
		<Footer />
	</div>
</template>

<script>
import ProfileUser from '@/components/ProfileUser';
import ProfileSchool from '@/components/ProfileSchool';
import PasswordChangeModal from '@/components/PasswordChangeModal';
import ProfileSchoolChangeModal from '@/components/ProfileSchoolChangeModal';
import FeedbackIcon from '@/components/FeedbackIcon';
import DeleteUserModal from '@/components/DeleteUserModal';
import Footer from '@/components/Footer';

// 유저 정보 관련 마이페이지 + 로그아웃 기능
export default {
	name: 'Profile',
	metaInfo: {
		// 페이지 제목 설정
		title: '내 정보',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
		],
	},
	mounted() {
		this.getUser();
		this.getProfile();
		this.getSchool();
	},
	data() {
		return {
			// 유저 기본 데이터
			user: {
				full_name: '',
				email: '',
				kits: [],
				birth_date: '',
				phone: '',
			},
			school: {
				id: 0,
				name: '',
				address: '',
				license_due: '',
				code: '',
				region: '',
				district: '',
				zipcode: '',
				teacher_contactpoint: {
					full_name: '',
				},
				game_kits: [],
				has_wifi: false,
				has_tablet: false,
				has_printer: false,
				has_student_mobile: false,
				license_active: true,
				license_expired: false,
			},
			new_full_name: '',
			new_phone: '',
			button_pressed: false,
			current_password: '',
		};
	},
	computed: {
		// 필수 입력 처리
		full_name_validation() {
			return this.new_full_name.length > 0;
		},
		// 필수 입력 처리
		phone_validation() {
			return this.new_phone.length > 9;
		},
	},
	methods: {
		// 로그아웃 요청
		makeSignOut() {
			this.$store.dispatch('SIGNOUT').then(() => {
				alert('로그아웃되었습니다.');
				this.$router.push('/');
			});
		},

		// 유저 정보 조회
		getUser() {
			this.$store
				.dispatch('GET_USER')
				.then((res) => {
					this.user.email = this.$store.state.email;
					return res;
				})
				.catch((e) => e);
		},

		// 프로필 정보 조회 요청
		getProfile() {
			this.$store.dispatch('GET_PROFILE').then((res) => {
				if (this.$store.state.serverStatus === 200) {
					const userProfile = res.data.results.pop();
					Object.assign(this.user, userProfile);
					this.new_full_name = this.user.full_name;
					this.new_phone = this.user.phone;
					// console.log(this.user)
				}
				if (this.$store.state.serverStatus === 400) {
					console.log('failed to get user data');
				}
				// 토큰 기간 만료로 요청 실패
				if (this.$store.state.serverStatus === 401) {
					this.$router.push('/login?redirect=%2Fmypage');
					// window.location.reload()
					alert('다시 로그인하십시오.');
				}
			});
		},

		// 학교 정보 조회 요청
		getSchool() {
			this.$store.dispatch('GET_SCHOOL').then((res) => {
				if (this.$store.state.serverStatus === 200) {
					const school = res.data.results.pop();
					Object.assign(this.school, school);
					// console.log(school)
				}
				if (this.$store.state.serverStatus === 400) {
					console.log('failed to get school data');
				}
				// 토큰 기간 만료로 요청 실패
				if (this.$store.state.serverStatus === 401) {
					this.$router.push('/login?redirect=%2Fmypage');
					// window.location.reload()
					// alert('다시 로그인하십시오.')
				}
			});
		},

		reloadWindow() {
			window.location.reload();
		},

		putProfile() {
			// 연속으로 안 눌리게 쿨타임 주기
			this.button_pressed = true;
			setTimeout(() => {
				this.button_pressed = false;
			}, 250);

			// actions 메소드 호출
			this.$store
				.dispatch('PATCH_PROFILE', {
					full_name: this.new_full_name,
					phone: this.new_phone,
					// 'birth_date': this.user.birth_date,
					// 'school': this.school.id
				})
				.then((res) => {
					// 요청 정상 처리
					if (res.status === 200) {
						alert('프로필 정보 변경 완료');
						this.getProfile();
						this.$bvModal.hide('profile-edit-modal');
					}
					// 토큰 기간 만료로 요청 실패
					if (this.$store.state.serverStatus === 401) {
						this.$router.push('/login?redirect=%2Fmypage');
						// window.location.reload()
					}
					if (this.$store.state.serverStatus === 400) {
						alert('변경을 시도하는 중 오류가 발생했습니다. 입력하신 정보를 확인해주세요.');
						// if (res.data.hasOwnProperty('current_password')) {
						//   this.current_password_validation = false
						// }
						console.log('error editing profile');
					}
				});
		},

		initializeData() {
			this.new_full_name = this.user.full_name;
			this.new_phone = this.user.phone;
		},
	},
	components: {
		ProfileUser,
		ProfileSchool,
		PasswordChangeModal,
		ProfileSchoolChangeModal,
		FeedbackIcon,
		DeleteUserModal,
		Footer,
	},
};
</script>

<style scoped>
.container,
.button-container {
	margin-bottom: 1rem;
}

.main {
	/*padding-bottom: 0;*/
}

.orange {
	background-color: #ff6936;
}

@media (min-width: 1200px) {
	.container {
		max-width: 540px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 472px;
	}
	.main {
		max-width: 1080px;
		margin: auto;
	}
}

@media (max-width: 576px) {
	.btn {
		padding-left: 0.275rem;
		padding-right: 0.275rem;
	}
}
</style>
